import { Chart } from "react-google-charts";
import { FranchiseOmniChannelDate, FranchiseOmniChannelLocation, SystemReportDate } from "services/franchiseService";
import { useSelector } from "react-redux";
import { selectWhiteLabel } from "store/WhiteLabel/WhiteLabelStore";

interface GraphProps{
    dates: FranchiseOmniChannelDate[], //Unused
    locationReports: FranchiseOmniChannelLocation[],
    systemReportDates: FranchiseOmniChannelDate[],
    selectedMetrics: string[],
    bySelectedLocations?: boolean,
    selectedChannel?: String,
}


export const SystemOverviewChart = (props: GraphProps) =>{

    const whiteLabel = useSelector(selectWhiteLabel);

    //TODO: Debe usarse props.systemReportDates en la función de transformData. Debe estar siempre
    //presente los valores de estas fechas en el gráfico.
    //las fechas al seleccionar una o más locations deben verse siempre "debajo" de estas fechas ya que son la suma total.

    // Function to transform FranchiseOmniChannelLocation data to Google Charts data format
    function transformData(locations: FranchiseOmniChannelLocation[], metrics: string[] ): any[] {

        // Initialize an empty array to store all dates
        const allDates: Date[] = [];

        /*locations.forEach(location => {
            location.dates.forEach(dateObj => {
                // Convert date string to JavaScript Date object and push it to allDates array
                const [year, month, day] = dateObj.date.split('-');
                // Create a new JavaScript Date object with components
                allDates.push(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)));
            });
        });*/

        
        props.systemReportDates.forEach(systemReportDate => {
            const [year, month, day] = systemReportDate.date.split('-');
            // Create a new JavaScript Date object with components
            allDates.push(new Date(parseInt(year), parseInt(month) - 1, parseInt(day)));
        });

        // Sort the dates array
        allDates.sort((a, b) => a.getTime() - b.getTime());

        // Construct the header row for Google Charts data
        const headerRow = ["Date"];

        if( props.systemReportDates.length > 0 ){
            headerRow.push("System cpa");
        }
        
        if( props.bySelectedLocations ) {
            console.log('adding location header');
            console.log(props.locationReports);
            
            locations.forEach(location => {
                // Add location names to header row
                console.log(location.name);
                headerRow.push(location.name+" cpa"); 
                //headerRow.push(location.name+" ctr"); 
            }); 
        }/*else {
            metrics.forEach(metric => {
                headerRow.push(metric);
            })
        }*/
        // Initialize the data array with the header row
        const data = [headerRow];
        var aggregatedData: any = {};
        var dataVar: any;


        // Iterate over each unique date
        allDates.forEach(date => {
            const formattedDate = date.toISOString().split('T')[0]; 
            // console.log(formattedDate);
            const rowData = [date] as any[];

            aggregatedData = {
                impressions: 0,
                clicks: 0,
                ctr: 0,
                spend: 0,
                cpc: 0,
                conversions: 0
            }

            locations.forEach(location => {
                const dateData = location.dates.find(dateObj => dateObj.date === formattedDate);
                if (dateData) {
                        metrics.forEach(metric => {
                            switch( metric ){
                                case "impressions":
                                    dataVar = dateData.impressions 
                                    props.bySelectedLocations ?  rowData.push(dataVar) : aggregatedData.impressions += dataVar
                                    break;
                                case "clicks":
                                    dataVar = dateData.clicks 
                                    props.bySelectedLocations ?  rowData.push(dataVar) : aggregatedData.clicks += dataVar
                                    break;
                                case "ctr":
                                    if ( dateData.ctr == 0 && props.selectedChannel != "google" && props.selectedChannel != "facebook" ){
                                        dataVar = Number( ((dateData.clicks/dateData.impressions)*100).toFixed(2))
                                    }else { 
                                        dataVar = Number(((isNaN(dateData.ctr) ? 0 : dateData.ctr) * 100).toFixed(2)) 
                                    }
                                    props.bySelectedLocations ?  rowData.push(dataVar) : aggregatedData.ctr += dataVar
                                    break;
                                case "spend":
                                    dataVar = Number(((isNaN(dateData.spend) ? 0 : dateData.spend) / 1000000).toFixed(2))
                                    props.bySelectedLocations ?  rowData.push(dataVar) : aggregatedData.spend += dataVar
                                    break;
                                case "cpc":
                                    //dataVar = Number( ((isNaN(dateData.cpc) ? 0 : dateData.cpc) / 1000000).toFixed(2) )
                                    if ( dateData.cpc == 0 && props.selectedChannel != "google" && props.selectedChannel != "facebook" ){
                                        dataVar = dateData.clicks > 0 ? Number( ((dateData.spend / dateData.clicks) / 1000000).toFixed(2)) : 0;
                                    }else { 
                                        dataVar = Number( ((isNaN(dateData.cpc) ? 0 : dateData.cpc) / 1000000).toFixed(2) )
                                    }
                                    props.bySelectedLocations ?  rowData.push(dataVar) : aggregatedData.cpc += dataVar
                                    break;
                                case "cpa":
                                    let cplData = Number((dateData.cpl / 1000000).toFixed(2));
                                    rowData.push( isNaN(cplData) ? 0 : cplData );
                                    break;
                                case "conversions":
                                    dataVar = Number(((isNaN(dateData.pmConversions) ? 0 : dateData.pmConversions)).toFixed(2)) 
                                    props.bySelectedLocations ?  rowData.push(dataVar) : aggregatedData.conversions += dataVar
                                    break;
                            }
                        })
                } else {
                    // If data for the current date is not available for this location, push null values
                    rowData.push(0);
                }
            });

            /*if (!props.bySelectedLocations) {
                rowData.push(aggregatedData.impressions)
                rowData.push(aggregatedData.ctr)
            }*/

            //Insert the system impressions and ctr if needed
            if( props.systemReportDates.length > 0 ){
                const dateData = props.systemReportDates.find(dateObj => dateObj.date === formattedDate);
                if( dateData ){
                    let cplData = Number((dateData.cpl / 1000000).toFixed(2));
                    rowData.splice(1, 0, cplData); //TODO: insert system cpl here.
                }else{
                    rowData.splice(1, 0, 0);
                }
                
            }

            data.push(rowData);
        });
        console.log(data);
        return data;
    }

    //Build the columns
    const columns = [
        {
          type: "date",
          label: "Month"
        },
        ...props.selectedMetrics.map(metric => ({
          type: "number",
          label: metric
        }))
    ];

    

    const chartData = transformData(props.locationReports, props.selectedMetrics);
    return (
        (props.locationReports.length > 0 || props.systemReportDates.length > 0) ? (
            <Chart
                chartType="LineChart"
                data={chartData}
                width="100%"
                height="400px"
                options={
                    {
                        
                        hAxis: {
                            format: 'MMM dd'
                        },
                        series: {
                            0: { targetAxisIndex: 0, color: whiteLabel.primaryColor },
                            ...(props.selectedMetrics.length >= 1 ? { 1: { targetAxisIndex: 0, color: whiteLabel.secondaryColor } } : {}),
                        },
                        vAxes: [
                            ...props.selectedMetrics.map((metric,index) => ({
                                title: props.selectedMetrics[index],
                                minValue: 0
                            }))
                        ],
                        chartArea: {
                            top: 5,
                            bottom: 5,
                        }
                    }
                }   
            >
            </Chart>
        ):
            <div>
                <p>No data available, check your API connections or make sure CSV data has been imported.</p>
            </div>
        
    )
}

export default SystemOverviewChart;
