import styles from './Chart.module.css'
import { FranchiseOmniChannelDate, FranchiseOmniChannelLocation, FranchiseOmniChannelRow, SystemReportDate } from 'services/franchiseService';
import { useEffect, useState } from 'react';
import SystemOverviewChart from './SystemOverviewChart';
import { useSelector } from "react-redux";
import { selectUserAccount } from "../../../store/Account/AccountStore";
import { UserRole } from "../../../models/User";

import { Form, Select } from 'antd';  
import { ConfigurationServicePlaceholders } from 'aws-sdk/lib/config_service_placeholders';

interface CustomChannelOptions {
  label: string;
  value: string;
}

interface OmniChartProps {
  channelReports: FranchiseOmniChannelRow[];
  customChannelOptions: CustomChannelOptions[];
  defaultChannel: String; //The default channel to show when "all" is selected.
  selectedChannel: String; //The actual currently selected channel.
  selectedLocations: String[];
  isGeneratingSystemReport: boolean;
  systemReportDates: SystemReportDate[];
}

export const SimplifiedOmniChart = (props: OmniChartProps) => {

  const selectedMetrics = ["cpa"];


  const [selectedChannel, setSelectedChannel] = useState(props.defaultChannel);
  const [selectedBenchmark, setSelectedBenchmark] = useState('my-trends');
  const [selectedLocations, setSelectedLocations] = useState(props.selectedLocations);

  const user = useSelector(selectUserAccount);


  //First verifies how many reports are included here.
  let facebookDatesReport: FranchiseOmniChannelDate[] = [];
  let facebookSystemReport: FranchiseOmniChannelDate[] = [];
  let googleDatesReport: FranchiseOmniChannelDate[] = [];
  let googleSystemReport: FranchiseOmniChannelDate[] = [];

  let facebookLocationsReport: FranchiseOmniChannelLocation[] = [];
  let googleLocationsReport: FranchiseOmniChannelLocation[] = [];

  props.channelReports.forEach(element => {

    if (element.name == "meta") {
      facebookDatesReport = element.dates;
      facebookSystemReport = element.systemReport.dates;
      facebookLocationsReport = element.locationReports;
    }
    if (element.name == "google") {
      googleDatesReport = element.dates;
      googleSystemReport = element.systemReport.dates;
      googleLocationsReport = element.locationReports;
    }

  });


  let dates: FranchiseOmniChannelDate[] = []; 
  let systemDates: FranchiseOmniChannelDate[] = [];
  let locationReports: FranchiseOmniChannelLocation[] = [];
  
  /*if(props.selectedChannel == "all"){
    //fallback to facebook
    dates = facebookDatesReport;
    console.log('Switching to default, which is facebook / meta');
  }*/
  if ( (props.selectedChannel == "all" && selectedChannel == "meta") || props.selectedChannel == "facebook") {
    dates = facebookDatesReport;
    systemDates = facebookSystemReport;
    if (props.selectedLocations.length > 0) {
      locationReports = facebookLocationsReport.filter( location => props.selectedLocations.includes(location.key) == true );
    }else {
      locationReports = []; //Empty report if no location is selected; now the system report dates will show these values.
    }
    

  } else if ( (props.selectedChannel == "all" && selectedChannel == "google") || props.selectedChannel == "google") {

    dates = googleDatesReport;
    systemDates = googleSystemReport;
    if (props.selectedLocations.length > 0) {
      locationReports = googleLocationsReport.filter( location => props.selectedLocations.includes(location.key) == true );
    }else {
      locationReports = []; //Empty report if no location is selected; now the system report dates will show these values.
    }

  } else {
    
    let desiredChannelName : String = "";
    if( props.selectedChannel === "all"){
      desiredChannelName = selectedChannel;
    }else{
      desiredChannelName = props.selectedChannel.toLowerCase();
    }

    const desiredChannel = props.channelReports.find(channel => channel.key == desiredChannelName);
    
    if (desiredChannel !== undefined) {
      dates = desiredChannel.dates;
      systemDates = desiredChannel.systemReport.dates;
      if (props.selectedLocations.length > 0) {
        locationReports = desiredChannel.locationReports.filter( location => props.selectedLocations.includes(location.key) == true )  
      }else {
        locationReports = desiredChannel.locationReports.filter( location => props.selectedLocations.includes(location.key) == true )  
      }
    } else {
      dates = [];
      locationReports = [];
      systemDates = [];
    }
  }


  return <div className={styles['chart-main-container'] + " !mt-[-50px] res:w-[500px]"}>
    { (props.isGeneratingSystemReport) 
      ?
        <p>Please wait...</p>
      :
        <SystemOverviewChart 
          selectedMetrics={selectedMetrics} 
          dates={dates} 
          systemReportDates={systemDates}
          locationReports={locationReports} 
          bySelectedLocations={props.selectedLocations.length > 0} 
          selectedChannel={props.selectedChannel}
        ></SystemOverviewChart>
    }
  </div>
};

export default SimplifiedOmniChart
