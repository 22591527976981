import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-down.svg";
import { Button } from "antd";
import { setOpen as setUploadOpen } from "../../../store/PlatformSupport/Upload";
import { setOpen as setDownloadOpen } from "../../../store/PlatformSupport/Download";
import { setOpen as setSupportFormOpen } from "../../../store/PlatformSupport/SupportForm";
import { selectIsInvoicedState, selectIsSendingState, selectMonthlyInsertionOrderList, setFormInvoiced, setOpen as setOrderFormOpen } from "../../../store/PlatformSupport/OrderForm";
import {InfoCircleOutlined, EditOutlined, SendOutlined} from "@ant-design/icons";

import { selectWhiteLabel } from "../../../store/WhiteLabel/WhiteLabelStore";
import { selectLoading } from "../../../store/PlatformSupport/OrderForm";
import { fetchInsertionOrderList } from "../../../store/PlatformSupport/OrderForm";
import { fetchPlatformOrderList, selectMonthlyPlatformOrderList, selectLoading as selectPlatformOrdersLoading } from "../../../store/AdCampaigns/AdCampaignStore";

import { Backdrop } from "@mui/material";
import { ReactComponent as LoaderIcon } from "../../../assets/icons/loader-onmiChannel.svg";
import { ReactComponent as SendingIcon } from "../../../assets/icons/loader-sending.svg";
import { MonthlyInsertionOrders } from "services/orderFormService";
import { WhiteLabel } from "../../../models/WhiteLabel";
import { OrderForm } from "../SystemTools/OrderForm";
import { MonthlyPlatformOrders } from "../../../services/adCampaignService";

const ActiveOrders = () => {
  const dispatch = useDispatch();
  const whiteLabel = useSelector(selectWhiteLabel);
  const loading = useSelector(selectLoading);
  const sending = useSelector(selectIsSendingState);
  const platformOrdersLoading = useSelector(selectPlatformOrdersLoading);
  const monthlyInsertionOrderList = useSelector(selectMonthlyInsertionOrderList);
  const monthlyPlatformOrderList = useSelector(selectMonthlyPlatformOrderList);
  const isInvoiced = useSelector(selectIsInvoicedState);

  const fechaActual = new Date();
  const mesActual = fechaActual.getMonth() ;
  const [month, setMonth] = useState(mesActual);
  const [editMode, setEditMode] = useState(false);
  const [sendMode, setSendMode] = useState(false);
  const [readMode, setReadMode] = useState(false);
  const [selectedInsertionOrder, setSelectedInsertionOrder] = useState("");
  const [currentMonthInsertionOrders, setCurrentMonthInsertionOrders] = useState({} as MonthlyInsertionOrders);

  //Defined as a "monthly" value, however at the moment everything is grouped inside a single month named "default".
  //This value is returned as that by the backend intentionally.
  //In the future this will be most likely be required to be split in actual months, like the insertion orders.
  const [currentMonthPlatformOrders, setCurrentMonthPlatformOrders] = useState({} as MonthlyPlatformOrders);

  
  const renderMonthlyInsertionOrders = ( monthlyInsertionOrders : MonthlyInsertionOrders, whiteLabel : WhiteLabel ) => {
    if( monthlyInsertionOrders.orders === undefined ){
      return <div>
          <p className="text-base text-black">No insertion orders found for this month</p>
        </div>
    }else{
      const renderedList = monthlyInsertionOrders.orders.map((order) => (
        <div className="grid items-center w-full grid-cols-12 mb-4">
          <div className="col-span-3">{order.poNumber}</div>
          <div className="col-span-2 text-left">{order.date}</div>
          <div className={ !whiteLabel.agency ? "col-span-2 flex justify-start items-center capitalize" : "col-span-2 flex justify-end items-center capitalize"}>
            <span className="w-14">{order.status}</span>
            { order.status == "invoiced" && (
              <span className="ml-3" onClick={() => {
                  setSelectedInsertionOrder(order.id);
                  setEditMode(false);
                  setSendMode(false);
                  setReadMode(true);
                  dispatch(setOrderFormOpen(true))
              }}>
                <InfoCircleOutlined
                  style={{ color: whiteLabel.primaryColor, fontSize: "20px" }}
                />
              </span>
            )}

            { order.status == "new" && (
              <div>
                <span className="ml-3" onClick={() => {
                  setSelectedInsertionOrder(order.id);
                  setEditMode(true);
                  setSendMode(false);
                  setReadMode(false);
                  dispatch(setOrderFormOpen(true))
                }}>
                  <EditOutlined
                    style={{ color: whiteLabel.primaryColor, fontSize: "20px" }}
                  />
                </span>
                <span className="ml-3" onClick={() => {
                  setSelectedInsertionOrder(order.id);
                  setEditMode(false);
                  setSendMode(true);
                  setReadMode(false);
                  dispatch(setOrderFormOpen(true))
                }}>
                  <SendOutlined
                    style={{ color: whiteLabel.primaryColor, fontSize: "20px" }}
                  />
                </span>
              </div>
            )}
          </div>
        </div>
      ))
      return renderedList;
    }
  }


  const renderMonthlyPlatformOrders = ( monthlyPlatformOrders : MonthlyPlatformOrders, whiteLabel : WhiteLabel ) => {
    if( monthlyPlatformOrders.orders === undefined ){
      return <div>
          <p className="text-base text-black">No platform orders found for this month</p>
        </div>
    }else{
      const renderedList = monthlyPlatformOrders.orders.map((order) => (
        <div className="grid items-center w-full grid-cols-12 mb-4">
          <div className="col-span-3 ">{order.locationName}</div>
          <div className="flex justify-start col-span-2">{order.date}</div>
          <div className="flex items-center justify-start col-span-2">
            <span className="capitalize w-14">{order.status}</span>
            <a className="mt-2 ml-3 " target="_blank" href={order.link}>
              <InfoCircleOutlined
                style={{ color: whiteLabel.primaryColor, fontSize: "20px" }}
              />
            </a>
          </div>
          <div className="flex justify-start col-span-2">{order.channelList}</div>
        </div>
      ))
      return renderedList;
    }
  }

  const nextMonth = ( ) => {
    if ( month+1 <= mesActual ) {
      setMonth(month+1)
    }
  }
  const previuosMonth = ( ) => {
    if ( month-1 > mesActual-5) {
      setMonth(month-1)
    }
  }
  var nameMonth = new Date(month, month).toLocaleString('en-EN', { month: 'long' })

  const findInsertionOrdersByMonth = (targetMonth : string) => {
    return monthlyInsertionOrderList.find((monthlyInsertionOrder) => monthlyInsertionOrder.month === targetMonth);
  };
  const findPlatformOrdersByMonth = (targetMonth : string) => {
    return monthlyPlatformOrderList.find((monthlyPlatformOrder) => monthlyPlatformOrder.month === targetMonth);
  };

  //Fetch the list of monthlyInsertionOrder
  useEffect(() => {
    console.log('fetching');
    dispatch(fetchInsertionOrderList())
    dispatch(fetchPlatformOrderList());
  },[]);// eslint-disable-line react-hooks/exhaustive-deps

  //Fetches the proper MonthlyInvoices object according to the choosen month's name.
  useEffect(() => {
    const foundMonthlyInsertionOrders = findInsertionOrdersByMonth(nameMonth);
    if (foundMonthlyInsertionOrders) {
      setCurrentMonthInsertionOrders( foundMonthlyInsertionOrders );
    } else {
      //defaults to an empty object if not found.
      setCurrentMonthInsertionOrders({} as MonthlyInsertionOrders);
    }

    const foundMonthlyPlatformOrders = findPlatformOrdersByMonth(nameMonth);
    if (foundMonthlyPlatformOrders) {
      setCurrentMonthPlatformOrders( foundMonthlyPlatformOrders );
    } else {
      //defaults to an empty object if not found.
      setCurrentMonthPlatformOrders({} as MonthlyPlatformOrders);
    }
  }, [nameMonth]);
  
  //Only print the list
  useEffect(() => {
    if(monthlyInsertionOrderList.length > 0 ){
      const foundMonthlyInsertionOrders = findInsertionOrdersByMonth(nameMonth);
      if (foundMonthlyInsertionOrders) {
        setCurrentMonthInsertionOrders( foundMonthlyInsertionOrders );
      } else {
        //defaults to an empty object if not found.
        setCurrentMonthInsertionOrders({} as MonthlyInsertionOrders);
      }
    }
  }, [monthlyInsertionOrderList]);

  //Only print the list
  useEffect(() => {
    if(monthlyPlatformOrderList.length > 0 ){
      const foundMonthlyPlatformOrders = findPlatformOrdersByMonth(nameMonth);
      if(foundMonthlyPlatformOrders){
        setCurrentMonthPlatformOrders(foundMonthlyPlatformOrders);
      }else{
        setCurrentMonthPlatformOrders({} as MonthlyPlatformOrders );
      }
    }
  }, [monthlyPlatformOrderList]);

  useEffect(() => {
    if(isInvoiced){
      dispatch(fetchInsertionOrderList())
      alert('Order was invoiced successfully');
      dispatch(setFormInvoiced(false));
    }
  }, [isInvoiced]);


  return (
    <div>
        <div className="container">
          <div className="mb-3 text-2xl">Active orders</div>
          

          <div className="grid items-start grid-cols-5 res:grid-cols-1 gap-x-7">
            <div className="col-span-4 res:col-span-1 ">
              <div className="flex justify-between">
                <div className="selectMonth h-fit">
                { month-1 <= mesActual-5 ? (
                    <div className="pointer-events-none arrowPrev">
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.2]  rotate-[90deg] " />
                    </div>
                  ) : (
                    <div className="arrowPrev" onClick={ previuosMonth }>
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.6]  rotate-[90deg] " />
                    </div>
                  ) }
                  
                  <div className="text-[15px] capitalize mx-5 w-[80px] text-center"> { nameMonth } </div>
                  { month+1 > mesActual ? (
                    <div className="pointer-events-none arrowNext">
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.2]  rotate-[170deg] " />
                    </div>
                  ) : (
                    <div className="arrowNext" onClick={ nextMonth }>
                      <ArrowIcon className="res:ml-0 svg-black cursor-pointer  hover:opacity-[1]  opacity-[0.6]  rotate-[170deg] " />
                    </div>
                  ) }
                  
                </div>
              </div>
              
              <div className={'col-span-2 dividerCustom w-9/12 my-5'}>
                  <label>Insertion orders</label>
                  <div></div>
              </div>

              <div className="flex flex-wrap w-full">
                {renderMonthlyInsertionOrders(currentMonthInsertionOrders, whiteLabel)}
              </div>


            </div>

          </div>
          
          <div className="grid items-start grid-cols-5 mt-5 res:grid-cols-1 gap-x-7">
            <div className="col-span-4 res:col-span-1 ">
                <div className={'col-span-2 dividerCustom w-full my-5'}>
                    <label>Platform orders</label>
                    <div></div>
                </div>

                <div className="flex flex-wrap w-full">
                  {renderMonthlyPlatformOrders(currentMonthPlatformOrders, whiteLabel)}
                </div>
            </div>
          </div>

          <OrderForm readMode={readMode} editMode={editMode} sendMode={sendMode} insertionOrderId={selectedInsertionOrder}/>
          
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <LoaderIcon className="loader-icon" />
          {/* <CircularProgress color="inherit" /> */}
        </Backdrop>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={sending}
        >
          <SendingIcon className="loader-icon" />
          {/* <CircularProgress color="inherit" /> */}
        </Backdrop>
    </div>
  );
};

export default ActiveOrders;
